var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入门店名称" },
                    model: {
                      value: _vm.query.storeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "storeName", $$v)
                      },
                      expression: "query.storeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveStore
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "storeId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "storeId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "门店logo", width: "90", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            attrs: {
                              src: scope.row.storeLogo,
                              "preview-src-list": [scope.row.storeLogo],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "门店名称",
                  "min-width": "160",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所在区域",
                  "min-width": "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.provinceName +
                              "-" +
                              scope.row.cityName +
                              "-" +
                              scope.row.districtName
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeAddress",
                  label: "门店地址",
                  "min-width": "230",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unique",
                  label: "手机号",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.unique || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelId",
                  label: "机构",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.channelId || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeNum",
                  label: "门店号",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.storeNum || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appId",
                  label: "应用号",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.appId || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appSecret",
                  label: "授权密钥",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.appSecret || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isShow",
                  label: "是否显示",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isShow == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.$store.getters.roleName == "超级用户角色" ||
              _vm.$store.getters.roleName == "管理员"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "agentName",
                      label: "代理商名称",
                      "min-width": "120",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.agentName || "--"))]
                          },
                        },
                      ],
                      null,
                      false,
                      1334874211
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "orderWeight",
                  label: "权重值",
                  width: "90",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "addTime",
                  label: "添加时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(_vm._s(_vm._f("formatDate")(scope.row.addTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm.permission.updateStore ||
              _vm.permission.removeStore ||
              _vm.permission.selectStore ||
              _vm.permission.editAgentId
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "140",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.permission.updateStore
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "编辑",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "success",
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-edit-outline",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickEditInfo(scope.row)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.permission.removeStore
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "删除",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title: "您确定要删除么？",
                                            "icon-color": "red",
                                          },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.deleteAdmin(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            staticClass: "danger",
                                            attrs: {
                                              slot: "reference",
                                              icon: "el-icon-delete",
                                              type: "text",
                                            },
                                            slot: "reference",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.permission.selectStore
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "选择",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "success",
                                        attrs: {
                                          icon: "el-icon-circle-check",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectStoreHandle(
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.permission.editAgentId
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: `${
                                          scope.row.agentId ? "修改" : "设置"
                                        }代理商`,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "success",
                                        attrs: {
                                          icon: "el-icon-user",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickSetEditAgentInfo(
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.permission.editAgentId && scope.row.agentId
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "移除代理商",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title: "您确定要移除代理商么？",
                                            "icon-color": "red",
                                          },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.clickRemoveAgentInfo(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            staticClass: "danger",
                                            attrs: {
                                              slot: "reference",
                                              icon: "el-icon-remove-outline",
                                              type: "text",
                                            },
                                            slot: "reference",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2080071781
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.storeId ? "编辑" : "添加"}门店`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "120px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "门店logo", prop: "storeLogo" },
                },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.editForm.storeLogo,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(_vm.editForm, "storeLogo", $event)
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(_vm.editForm, "storeLogo", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店名称", prop: "storeName" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入门店名称" },
                    model: {
                      value: _vm.editForm.storeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "storeName", $$v)
                      },
                      expression: "editForm.storeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "unique" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入电话号" },
                    model: {
                      value: _vm.editForm.unique,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "unique", $$v)
                      },
                      expression: "editForm.unique",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "机构", prop: "channelId" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入机构" },
                    model: {
                      value: _vm.editForm.channelId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "channelId", $$v)
                      },
                      expression: "editForm.channelId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店", prop: "storeNum" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入门店号" },
                    model: {
                      value: _vm.editForm.storeNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "storeNum", $$v)
                      },
                      expression: "editForm.storeNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用号", prop: "appId" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入应用号" },
                    model: {
                      value: _vm.editForm.appId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "appId", $$v)
                      },
                      expression: "editForm.appId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "授权密钥", prop: "appSecret" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入授权密钥" },
                    model: {
                      value: _vm.editForm.appSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "appSecret", $$v)
                      },
                      expression: "editForm.appSecret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域", prop: "districtId" } },
                [
                  _c("el-cascader", {
                    ref: "myCascader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.regionList,
                      props: {
                        value: "regionId",
                        label: "regionName",
                        children: "children",
                      },
                      "collapse-tags": "",
                      clearable: "",
                      placeholder: "请选择所在区域",
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.cityValue,
                      callback: function ($$v) {
                        _vm.cityValue = $$v
                      },
                      expression: "cityValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店地址", prop: "storeAddress" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "fetch-suggestions": _vm.querySearchAsync,
                      placeholder: "请填写门店地址",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.editForm.storeAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "storeAddress", $$v)
                      },
                      expression: "editForm.storeAddress",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "地图" } }, [
                _c(
                  "div",
                  { staticClass: "mapContainer" },
                  [
                    _vm.addOrEditDialog
                      ? _c("MapView", {
                          attrs: { editForm: _vm.editForm },
                          on: { getAMap: _vm.getAMapHandle },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "经度", prop: "longitude" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "经度",
                      disabled: "",
                    },
                    model: {
                      value: _vm.editForm.longitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "longitude", $$v)
                      },
                      expression: "editForm.longitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纬度", prop: "latitude" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "纬度",
                      disabled: "",
                    },
                    model: {
                      value: _vm.editForm.latitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "latitude", $$v)
                      },
                      expression: "editForm.latitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示", prop: "isShow" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.editForm.isShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "isShow", $$v)
                      },
                      expression: "editForm.isShow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重", prop: "orderWeight" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请填写权重" },
                    model: {
                      value: _vm.editForm.orderWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "orderWeight", $$v)
                      },
                      expression: "editForm.orderWeight",
                    },
                  }),
                ],
                1
              ),
              _vm.roleName != "代理商"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "代理商", prop: "agentId" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: { placeholder: "请选择代理商", disabled: "" },
                        model: {
                          value: _vm.editForm.agentName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "agentName", $$v)
                          },
                          expression: "editForm.agentName",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "25px" },
                          attrs: { plain: "", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.clickSelectAgentBtn(1)
                            },
                          },
                        },
                        [_vm._v("选择")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "25px" },
                          attrs: { plain: "", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.clickSelectAgentBtn(2)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择代理商",
            visible: _vm.dialogAgentVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAgentVisible = $event
            },
          },
        },
        [
          _vm.dialogAgentVisible
            ? _c("agentList", {
                on: { changeSelectAgentInfo: _vm.changeAgentSelectSuccess },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }