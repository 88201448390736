import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
    constructor() {
        super()
    }

    //门店列表
    storeList(param) {
        return this.get('web/storeInfo/list',param)
    }
    //门店新增
    saveStore(param) {
        return this.post('web/storeInfo/save',param)
    }
    //门店修改
    updateStore(param) {
        return this.post('web/storeInfo/update',param)
    }
    //门店删除
    removeStore(param) {
        return this.post('web/storeInfo/remove',param)
    }

    // 添加/修改/取消代理商
    editAgent(param) {
        return this.post('/web/storeInfo/editAgentId',param)
    }
    
    
    

    
}

export default new BaseManager()