<template>
    <div id="mapContainer"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  props: ["editForm"],
  data() {
    return {
      map: null,
      AMap: null,
      markerList: [],
    };
  },
  created() {
    // this.initMap();
  },
  watch: {
    "editForm.latitude": {

      handler() {
        if (this.editForm.changeType == 1) {
          var marker = new this.AMap.Marker({
                position: new this.AMap.LngLat(this.editForm.longitude, this.editForm.latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                id: 3,
              });
          if (this.markerList.length) {
            this.map.remove(this.markerList[0]);
            this.markerList = [];
          }
          this.map.setCenter([this.editForm.longitude, this.editForm.latitude]);
          this.markerList.push(marker);
          this.map.add(marker);
        }
      },
    },

  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      var that = this;
      AMapLoader.load({
        key: "f62e27d1f7dfaabbe95b8892fc0d7069", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geocoder", "AMap.AutoComplete", "Geocoder","AutoComplete"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 版本
          plugins: ["overlay/SimpleMarker"], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "2.0", // Loca 版本
        },
      })
        .then((AMap) => {
          that.AMap = AMap;
          that.$emit("getAMap",that.AMap);
          that.map = new AMap.Map("mapContainer", {
            zoom: 15,
          });
          if (that.editForm.latitude) {
            that.map.setCenter([that.editForm.longitude,that.editForm.latitude])
            var marker = new AMap.Marker({
                position: new AMap.LngLat(that.editForm.longitude, that.editForm.latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                id: 3,
            });
            that.markerList.push(marker);
            that.map.add(marker);
          }

          that.map.on("click", function (ev) {
            that.editForm.changeType = 2;
            let { lng, lat } = ev.lnglat;
            that.AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: "全国",
              });
              // 使用geocoder做地理/逆地理编码
              geocoder.getAddress([lng, lat], function (status, result) {
                if (status === "complete" && result.info === "OK") {
                  if (result && result.regeocode) {
                    var marker = new AMap.Marker({
                      position: new AMap.LngLat(lng, lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                      id: 3,
                    });
                    if (that.editForm) {
                        that.editForm.longitude = lng;
                        that.editForm.latitude = lat;
                        that.editForm.storeAddress = result.regeocode.formattedAddress;
                    } else {
                      that.$emit("clickMapHandle", {
                        lng: lng,
                        lat: lat,
                        addressDetail: result.regeocode.formattedAddress,
                      });
                    }
                    if (that.markerList.length) {
                      that.map.remove(that.markerList[0]);
                      that.markerList = [];
                    }
                    that.markerList.push(marker);
                    that.map.add(marker);
                  }
                }
              });
            });
          });
        })
        .catch((e) => {
          console.error(e); //加载错误提示
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mapContainer {
  width: 100%;
  height: 380px;
}
</style>
