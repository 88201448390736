<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-input type="text" placeholder="请输入门店名称" v-model="query.storeName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain v-if="permission.saveStore" @click="onAdd">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'storeId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="storeId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="门店logo" width="90" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" :src="scope.row.storeLogo" :preview-src-list="[scope.row.storeLogo]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="storeName" label="门店名称" min-width="160" align="center"></el-table-column>
        <el-table-column label="所在区域" min-width="160" align="center">
          <template slot-scope="scope">{{scope.row.provinceName+'-'+scope.row.cityName+'-'+scope.row.districtName}}</template>
        </el-table-column>
        <el-table-column prop="storeAddress" label="门店地址" min-width="230" align="center"></el-table-column>
        <el-table-column prop="unique" label="手机号" width="120" align="center">
          <template slot-scope="scope">{{scope.row.unique||'--'}}</template>
        </el-table-column>
        <el-table-column prop="channelId" label="机构" width="90" align="center">
          <template slot-scope="scope">{{scope.row.channelId||'--'}}</template>
        </el-table-column>
        <el-table-column prop="storeNum" label="门店号" width="90" align="center">
          <template slot-scope="scope">{{scope.row.storeNum||'--'}}</template>
        </el-table-column>
        <el-table-column prop="appId" label="应用号" width="90" align="center">
          <template slot-scope="scope">{{scope.row.appId||'--'}}</template>
        </el-table-column>
        <el-table-column prop="appSecret" label="授权密钥" width="120" align="center">
          <template slot-scope="scope">{{scope.row.appSecret||'--'}}</template>
        </el-table-column>
        <el-table-column prop="isShow" label="是否显示" width="90" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="agentName" label="代理商名称" min-width="120" align="center" v-if="$store.getters.roleName=='超级用户角色'||$store.getters.roleName=='管理员'">
          <template slot-scope="scope">{{scope.row.agentName||'--'}}</template>
        </el-table-column>
        <el-table-column prop="orderWeight" label="权重值" width="90" align="center"></el-table-column>
        <el-table-column prop="addTime" label="添加时间" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.addTime | formatDate }}</template>
        </el-table-column>
        <el-table-column label="操作" width="140" align="center" fixed="right" v-if="permission.updateStore || permission.removeStore || permission.selectStore || permission.editAgentId">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.updateStore">
              <el-button class="success" type="text" icon="el-icon-edit-outline" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="permission.removeStore">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger"></el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="选择" placement="top" v-if="permission.selectStore">
              <el-button icon="el-icon-circle-check" type="text" class="success" @click="selectStoreHandle(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="`${scope.row.agentId?'修改':'设置'}代理商`" placement="top" v-if="permission.editAgentId">
              <el-button icon="el-icon-user" type="text" class="success" @click="clickSetEditAgentInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="移除代理商" placement="top" v-if="permission.editAgentId&&scope.row.agentId">
              <el-popconfirm title="您确定要移除代理商么？" icon-color="red" @confirm="clickRemoveAgentInfo(scope.row)">
                <el-button slot="reference" icon="el-icon-remove-outline" type="text" class="danger"></el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 查看门店详情 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <!-- 添加门店 -->
    <el-dialog :title="`${editForm.storeId ? '编辑' : '添加'}门店`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="门店logo" prop="storeLogo" class="is-required">
          <UploadImageNewOne :limit="1" :imgList.sync="editForm.storeLogo" accept="image/*">
          </UploadImageNewOne>
        </el-form-item>
        <el-form-item label="门店名称" prop="storeName">
          <el-input size="medium" v-model="editForm.storeName" placeholder="请输入门店名称"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="unique">
          <el-input size="medium" v-model="editForm.unique" placeholder="请输入电话号"></el-input>
        </el-form-item>
        <el-form-item label="机构" prop="channelId">
          <el-input size="medium" v-model="editForm.channelId" placeholder="请输入机构"></el-input>
        </el-form-item>
        <el-form-item label="门店" prop="storeNum">
          <el-input size="medium" v-model="editForm.storeNum" placeholder="请输入门店号"></el-input>
        </el-form-item>
        <el-form-item label="应用号" prop="appId">
          <el-input size="medium" v-model="editForm.appId" placeholder="请输入应用号"></el-input>
        </el-form-item>
        <el-form-item label="授权密钥" prop="appSecret">
          <el-input size="medium" v-model="editForm.appSecret" placeholder="请输入授权密钥"></el-input>
        </el-form-item>
        <el-form-item label="所在区域" prop="districtId">
          <el-cascader ref="myCascader" v-model="cityValue" :options="regionList" :props="{ value: 'regionId', label: 'regionName', children: 'children' }" collapse-tags clearable @change="handleChange" style="width: 100%" placeholder="请选择所在区域"></el-cascader>
        </el-form-item>
        <el-form-item label="门店地址" prop="storeAddress">
          <el-autocomplete v-model="editForm.storeAddress" :fetch-suggestions="querySearchAsync" placeholder="请填写门店地址" @select="handleSelect" style="width: 100%"></el-autocomplete>
        </el-form-item>
        <el-form-item label="地图">
          <div class="mapContainer">
            <MapView :editForm="editForm" @getAMap="getAMapHandle" v-if="addOrEditDialog" />
          </div>
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-input size="medium" v-model="editForm.longitude" placeholder="经度" disabled></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="latitude">
          <el-input size="medium" v-model="editForm.latitude" placeholder="纬度" disabled></el-input>
        </el-form-item>
        <el-form-item label="是否显示" prop="isShow">
          <el-checkbox v-model="editForm.isShow" :true-label="1" :false-label="0"></el-checkbox>
        </el-form-item>
        <el-form-item label="权重" prop="orderWeight">
          <el-input type="number" v-model="editForm.orderWeight" placeholder="请填写权重"></el-input>
        </el-form-item>
        <el-form-item label="代理商" prop="agentId" v-if="roleName != '代理商'">
          <el-input style="width: 320px" v-model="editForm.agentName" placeholder="请选择代理商" disabled></el-input>
          <el-button style="margin-left: 25px;" plain type="primary" @click="clickSelectAgentBtn(1)">选择</el-button>
          <el-button style="margin-left: 25px;" plain type="primary" @click="clickSelectAgentBtn(2)">删除</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 选择代理商 -->
    <el-dialog title="选择代理商" :visible.sync="dialogAgentVisible" :modal="false" :destroy-on-close="true" fullscreen class="absolute">
      <agentList v-if="dialogAgentVisible" @changeSelectAgentInfo="changeAgentSelectSuccess"></agentList>
    </el-dialog>

  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import storeManager from "@/request/storeManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
import MapView from "./sub/MapView.vue";
import systemSetManager from "@/request/systemSetManager.js";
import agentList from '@/components/page/agent/AgentUserList.vue'    //代理商
export default {
  mixins: [getList],
  components: {
    ShowContent,
    UploadImageNewOne,
    MapView,
    agentList,   //代理商
  },
  data () {
    // var validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入手机号'))
    //   } else {
    //     var regex = /^[1][3,4,5,7,8][0-9]{9}$/
    //     if (regex.test(value)) {
    //       callback()
    //     } else {
    //       callback(new Error('请输入正确的手机号'))
    //     }
    //   }
    // }
    return {
      requst: {
        module: storeManager,
        url: 'storeList',
        addUrl: 'saveStore',
        updateUrl: 'updateStore',
      },
      dialogSeeContent: false,
      rowInfo: {},
      query: {
        storeName: '',    //搜索词
      },
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        storeId: 0,
        storeLogo: "",
        storeName: "",
        unique: "",
        channelId: "",
        storeNum: "",
        appId: "",
        appSecret: "",
        storeAddress: "",
        longitude: "",
        latitude: "",
        isShow: 1,
        orderWeight: 9,
        changeType: 0,
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        districtId: "",
        districtName: "",
        agentId: 0,   //代理商ID
        agentName: '',   //代理商名称
      },
      addRules: {
        storeLogo: [{ required: true, message: "请上传门店logo", trigger: "blur" }],
        storeName: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        unique: [{ required: true, message: "请输入电话号", trigger: 'blur' }],
        storeAddress: [{ required: true, message: "请输入门店地址", trigger: "blur" }],
        orderWeight: [{ required: true, message: "请输入权重", trigger: "blur" }],
        districtId: [{ required: true, message: "请选择所在区域", trigger: "blur" }],
        longitude: [{ required: true, message: "请选择经度", trigger: "blur" }],
        latitude: [{ required: true, message: "请选择纬度", trigger: "blur" }],
      },
      AMap: null,
      cityValue: [],
      regionList: [],
      dialogAgentVisible: false,   //选择代理商
      selectAgentType: 0,   //选择代理商操作类型 1:添加/编辑门店、2:设置修改门店
      setAgentModel: {
        storeId: 0,    //门店id
        agentId: 0,   //代理商ID
      },
      // 
      roleName:'',
    };
  },
  created () {
    this.roleName = this.$store.getters.roleName;
    this.handlePermission();
    if (this.permission.regionList) {
      this.getRegionList();
    }
  },
  methods: {
    async getRegionList () {
      try {
        let data = await systemSetManager.regionList();
        if (data.code == 200) {
          this.regionList = this.toTree(data.data);
        }
      } catch (error) {
        //
      }
    },
    //处理数据
    toTree (data) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data = data.filter((item) => {
        return item.layerId == 1 || item.layerId == 2 || item.layerId == 3;
      });
      data.forEach((item) => {
        item.children = "";
      });
      let map = {};
      data.forEach((item) => {
        map[item.regionId] = item;
      });
      data.forEach((item) => {
        let parent = map[item.parentId];
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      });
      result.forEach((item) => {
        if (item.children == "") {
          item.disabled = true;
        }
      });
      return result;
    },
    handleChange (value) {
      console.log(value);
      if (value.length) {
        this.editForm.provinceId = value[0];
        this.editForm.cityId = value[1];
        this.editForm.districtId = value[2];
        this.editForm.provinceName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[0];
        this.editForm.cityName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[1];
        this.editForm.districtName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[2];
      }else{
        this.editForm.provinceId = 0;
        this.editForm.cityId = 0;
        this.editForm.districtId = 0;
        this.editForm.provinceName = '';
        this.editForm.cityName = '';
        this.editForm.districtName = '';
      }
    },
    hoverContent (row) {
      this.rowInfo = row;
      this.dialogSeeContent = true;
    },

    // 点击添加
    onAdd () {
      this.addOrEditDialog = true
    },

    // 点击添加---取消
    clickAddOrEditCancel () {
      this.resetAddOrEditData()
    },
    // 点击添加---确定
    clickAddOrEditConfirm () {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            let dataValue = JSON.parse(JSON.stringify(this.editForm))
            let data = await this.requst.module[this.editForm.storeId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: 'success'
              });
              this.$emit('success')
              this.refreshData()
              this.resetAddOrEditData()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }

        }
      })
    },

    // 清除添加数据
    resetAddOrEditData () {
      this.addOrEditDialog = false
      this.editForm = {
        storeId: 0,
        storeLogo: "",
        storeName: "",
        unique: "",
        channelId: "",
        storeNum: "",
        appId: "",
        appSecret: "",
        storeAddress: "",
        longitude: "",
        latitude: "",
        isShow: 1,
        orderWeight: 9,
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        districtId: "",
        districtName: "",
        agentId: 0,   //代理商ID
        agentName: '',   //代理商名称
      }
      this.cityValue = [];
    },
    // 点击编辑信息
    async clickEditInfo (row) {
      this.cityValue = [
        row.provinceId,
        row.cityId,
        row.districtId,
      ];
      this.editForm = {
        storeId: row.storeId,
        storeLogo: row.storeLogo,
        storeName: row.storeName,
        unique: row.unique,
        channelId: row.channelId,
        storeNum: row.storeNum,
        appId: row.appId,
        appSecret: row.appSecret,
        storeAddress: row.storeAddress,
        latitude: row.latitude,
        longitude: row.longitude,
        isShow: row.isShow,
        orderWeight: row.orderWeight,
        provinceId: row.provinceId,
        provinceName: row.provinceName,
        cityId: row.cityId,
        cityName: row.cityName,
        districtId: row.districtId,
        districtName: row.districtName,
        agentId: row.agentId,   //代理商ID
        agentName: row.agentName,   //代理商名称
      }
      this.addOrEditDialog = true
    },

    // 点击删除
    async deleteAdmin (row, index) {
      try {
        let { code, message } = await storeManager.removeStore({ storeId: row.storeId });
        if (code === 200) {
          // this.$message.success(message);
          this.$message({
            message: message,
            type: 'success'
          });
          // this.$message({ message: "删除成功", type: "success" });
          this.delRefresh(index);
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 地图
    getAMapHandle (value) {
      this.AMap = value;
    },

    querySearchAsync (queryString, cb) {
      var that = this;
      if (queryString.trim().length > 0) {

        // that.AMap.plugin("AMap.AutoComplete", function () {
        //   // 实例化Autocomplete
        //   var autoOptions = {
        //     //city 限定城市，默认全国
        //     city: "全国",
        //   };
        //   var autoComplete= that.AMap.AutoComplete(autoOptions);
        //   autoComplete.search(queryString, function(status, result) {
        //     console.log(result);
        //     if (status === "complete" && result.info === "OK") {
        //       var array = [];
        //       //搜索成功时，result即是对应的匹配数据
        //       for (const addressObj of result.tips) {
        //         var obj = {};
        //         obj.value = addressObj.district + addressObj.name;
        //         obj.name = addressObj.name;
        //         obj.address = addressObj.district;
        //         obj.lat = addressObj.location.lat;
        //         obj.lng = addressObj.location.lng;
        //         array.push(obj);
        //       }
        //       cb(array);
        //     } else {
        //       cb([]);
        //     }
        //     // 搜索成功时，result即是对应的匹配数据
        //     // console.log(result,status,'*********1');
        //   });
        // });

        // JSAPI 2.0 输入提示插件名称由 AMap.Autocomplete 变更为 AMap.AutoComplete 啦！
        that.AMap.plugin("AMap.AutoComplete", function () {
          var autoOptions = {
            //city 限定城市，默认全国
            city: "全国",
          };
          // 实例化AutoComplete
          var autoComplete = new that.AMap.AutoComplete(autoOptions);
          // 根据关键字进行搜索
          autoComplete.search(queryString, function (status, result) {

            // 搜索成功时，result即是对应的匹配数据
            if (status == "complete" && result.info == "OK") {
              var array = [];
              //搜索成功时，result即是对应的匹配数据
              for (const addressObj of result.tips) {
                var obj = {};
                obj.value = addressObj.district + addressObj.name;
                obj.name = addressObj.name;
                obj.address = addressObj.district;
                obj.lat = addressObj.location.lat;
                obj.lng = addressObj.location.lng;
                array.push(obj);
              }
              cb(array);
            } else {
              cb([]);
            }
          });
        });
      } else {
        cb([]);
      }
    },
    handleSelect (item) {
      console.log("item", item);
      this.editForm.storeAddress = item.address + item.name;
      this.editForm.longitude = item.lng;
      this.editForm.latitude = item.lat;
      this.editForm.changeType = 1;


      // var obj = {
      //     position: [item.lng, item.lat],
      // };
      // this.mapModel.markers = [];
      // this.mapModel.markers.push(obj);
      // this.mapModel.center = [item.lng, item.lat];
    },
    //选择
    selectStoreHandle (row) {
      this.$emit("changeSelectStore", row)
    },
    // 添加、编辑选择代理商
    clickSelectAgentBtn (select_type) {
      if (select_type == 1) {
        if (!this.permission.selectAgent) return this.$message.error('您没有选择权限！');
        this.dialogAgentVisible = true  //选择代理商
        this.selectAgentType = 1   //选择代理商操作类型 1:添加/编辑门店、2:设置修改门店
      } else if (select_type == 2) {
        this.editForm.agentId = 0   //代理商ID
        this.editForm.agentName = ''  //代理商名称
      }
    },

    // 选择代理商成功
    changeAgentSelectSuccess (agent_val) {
      console.log('选择代理商成功', agent_val)
      if (this.selectAgentType == 1) {
        this.editForm.agentId = agent_val.userId   //代理商ID
        this.editForm.agentName = agent_val.name  //代理商名称
      } else if (this.selectAgentType == 2) {
        this.setAgentModel.agentId = agent_val.userId
        this.postSetEditRemoveAgentInfo()   // 设置、修改、移除--代理商
      }
      this.dialogAgentVisible = false
    },
    // 点击设置、修改代理商信息
    clickSetEditAgentInfo (row) {
      if (!this.permission.selectAgent) return this.$message.error('您没有选择权限！');
      this.setAgentModel = {
        storeId: row.storeId,    //门店id
        agentId: 0,   //代理商ID
      }
      this.dialogAgentVisible = true  //选择代理商
      this.selectAgentType = 2   //选择代理商操作类型 1:添加/编辑门店、2:设置修改门店
    },

    // 移除门店代理商
    clickRemoveAgentInfo (row) {
      this.setAgentModel = {
        storeId: row.storeId,    //门店id
        agentId: 0,   //代理商ID
      }
      this.postSetEditRemoveAgentInfo()   // 设置、修改、移除--代理商
    },
    // 设置、修改、移除--代理商
    async postSetEditRemoveAgentInfo () {
      this.showLoading = true
      try {
        let { code, message } = await storeManager.editAgent(this.setAgentModel);
        if (code === 200) {
          this.$message.success(message);
          this.refresCurrentPagehData()   //更新数据
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.setAgentModel = {
          storeId: 0,    //门店id
          agentId: 0,   //代理商ID
        }
        this.showLoading = false
      }
    },

  },
};
</script>

<style scoped>
.mapContainer {
  width: 100%;
  height: 380px;
}

#mapContainer {
  width: 100%;
  height: 380px;
}
</style>

